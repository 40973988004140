import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Rooms from '../views/Rooms.vue';
import Stage from '../views/Stage.vue';
import Pages from '../views/Pages.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import(/* webpackPrefetch: true */ '../views/Onboarding.vue'),
  },
  {
    path: '/pages/:page_id',
    name: 'Pages',
    component: Pages,
    props: true,
  },


  {
    path: '/livestream',
    name: 'Livestream',
    component: () => import(/* webpackPrefetch: true */ '../views/Livestream.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: Rooms,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import(/* webpackPrefetch: true */ '../views/Calendar.vue'),
  },

  {
    path: '/sorry',
    name: 'Sorry',
    component: () => import('../views/Sorry.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/profileEdit',
    name: 'ProfileEdit',
    component: () => import('../views/ProfileEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/userinfo',
    name: 'Userinfo',
    component: () => import('../views/UserinfoEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/ProfileBasicEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profilePicture',
    name: 'ProfilePictureEdit',
    component: () => import('../views/ProfilePictureEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkin',
    name: 'Checkin',
    component: () => import('../views/Checkin.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/participants',
    name: 'participants',

    component: () => import('../views/Participants.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/participant/:user_id',
    name: 'Participant',
    component: () => import('@/views/Participant.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/stage/:stage_id',
    name: 'Stage',
    component: Stage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/rc',
    name: 'Chat',
    component: () => import('../views/Chat.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/chat',
    name: 'Chat2',
    component: () => import('../views/ChatNew.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/chat/:userid',
    name: 'DirectChat2',
    component: () => import('../views/ChatNew.vue'),
    meta: {
      requiresAuth: true
    },
    props: true,
  },
  {
    path: '/rc/:rcname',
    name: 'DirectChat',
    component: () => import('../views/Chat.vue'),
    meta: {
      requiresAuth: true
    },
    props: true,
  },
  {
    path: '/webinar',
    name: "webinar",
    component: () => import('../views/Webinar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: 'list',
        component: () => import('../views/WebinarList.vue'),
        name: "webinar-list",
      },
      {
        path: 'watch/:id',
        component: () => import('../views/WebinarWatch.vue'),
        name: "webinar-watch",
      },
    ]
  },
  {
    path: '/checkin-admin',
    component: () => import('../views/admin/AdminCheckIn.vue'),
    name: "admin-checkin",
  },

  {
    path: '/admin',
    name: "admin",
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: 'calendar',
        component: () => import('../views/admin/AdminCalendarList.vue'),
        name: "admin-calendar",
      },
      {
        path: 'calendar/details/:id',
        component: () => import('../views/admin/AdminCalendarEdit.vue'),
        name: "admin-calendar-edit",
        props: true,
      },
      {
        path: 'pages',
        component: () => import('../views/admin/AdminPagesList.vue'),
        name: "admin-pages",
      },
      {
        path: 'pages/details/:id',
        component: () => import('../views/admin/AdminPagesEdit.vue'),
        name: "admin-pages-edit",
        props: true,
      },
      {
        path: 'eventCodes',
        component: () => import('../views/admin/AdminEventCodesList.vue'),
        name: "admin-eventcodes",
      },
      {
        path: 'eventCodes/details/:id',
        component: () => import('../views/admin/AdminEventCodesEdit.vue'),
        name: "admin-eventcodes-edit",
        props: true,
      },
      {
        path: 'userinfo',
        component: () => import('../views/admin/AdminUserinfoList.vue'),
        name: "admin-userinfo",
      },
      {
        path: 'userinfo/details/:id',
        component: () => import('../views/admin/AdminUserinfoEdit.vue'),
        name: "admin-pages-edit",
        props: true,
      },
      {
        path: 'menu',
        component: () => import('../views/admin/AdminMenuList.vue'),
        name: "admin-menu",
      },
      {
        path: 'menu/details/:id',
        component: () => import('../views/admin/AdminMenuEdit.vue'),
        name: "admin-menu-edit",
        props: true,
      },
      {
        path: 'tracking',
        component: () => import('../views/admin/AdminTrackingEdit.vue'),
        name: "admin-tracking",
      },
      {
        path: 'logs',
        component: () => import('../views/admin/AdminLogsList.vue'),
        name: "admin-logs",
      },
      {
        path: 'room',
        component: () => import('../views/admin/AdminRoomList.vue'),
        name: "admin-rooms",
      },
      {
        path: 'room/details/:id',
        component: () => import('../views/admin/AdminRoomEdit.vue'),
        name: "admin-rooms-edit",
        props: true,
      },
      {
        path: 'webinar',
        component: () => import('../views/admin/AdminWebinarList.vue'),
        name: "admin-webinars",
      },
      {
        path: 'webinar/details/:id',
        component: () => import('../views/admin/AdminWebinarEdit.vue'),
        name: "admin-webinars-edit",
        props: true,
      },
      {
        path: 'users',
        component: () => import('../views/admin/AdminUserList.vue'),
        name: "admin-users",
      },
      {
        path: 'users/details/:id',
        component: () => import('../views/admin/AdminUserView.vue'),
        name: "admin-users-view",
        props: true,
      },
      {
        path: 'users/edit/:id',
        component: () => import('../views/admin/AdminUserEdit.vue'),
        name: "admin-users-edit",
        props: true,
      },
      {
        path: 'groups',
        component: () => import('../views/admin/AdminGroupList.vue'),
        name: "admin-groups",
      },
      {
        path: 'groups/details/:id',
        component: () => import('../views/admin/AdminGroupEdit.vue'),
        name: "admin-groups-edit",
        props: true,
      },
      {
        path: 'rules',
        component: () => import('../views/admin/AdminRuleList.vue'),
        name: "admin-rules",
      },
      {
        path: 'rules/details/:id',
        component: () => import('../views/admin/AdminRuleEdit.vue'),
        name: "admin-rules-edit",
        props: true,
      },
      {
        path: 'rulesteps/:rule',
        component: () => import('../views/admin/AdminRuleStepList.vue'),
        name: "admin-rulesteps",
        props: true,
      },
      {
        path: 'rulestep/:rule/details/:id',
        component: () => import('../views/admin/AdminRuleStepEdit.vue'),
        name: "admin-rulesteps-edit",
        props: true,
      },
      {
        path: 'language',
        component: () => import('../views/admin/AdminLanguage.vue'),
        name: "admin-language",
      },
      {
        path: 'dashboard',
        component: () => import('../views/admin/AdminDashboard.vue'),
        name: "admin-dashboard",
      },
      {
        path: 'stages',
        component: () => import('../views/admin/AdminStages.vue'),
        name: "admin-stages",
      },
      {
        path: 'livestream',
        component: () => import('../views/admin/AdminLivestream.vue'),
        name: "admin-livestream",
      },
      {
        path: 'features',
        component: () => import('../views/admin/AdminFeatures.vue'),
        name: "admin-features",
      },
      {
        path: 'anonymous',
        component: () => import('../views/admin/AdminAnonymousMode.vue'),
        name: "admin-anonymousMode",
      },
      {
        path: 'basic',
        component: () => import('../views/admin/AdminBasicInfoEdit.vue'),
        name: "admin-basic",
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }

})

export default router
