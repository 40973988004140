<template>
  <div v-if="!isPageLoading">
    <template-loader
      v-bind:data="page"
      v-bind:type="page.template"
      v-bind:loading="isPageLoading"
    />
  </div>
  <div v-else></div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { USER_IMAGE_URL } from "@/common/config";
import {
  FETCH_PAGE,
  FETCH_PAGE_PUBLIC,
  GET_CONFIG,
} from "@/store/actions.type";
import TemplateLoader from "@/components/TemplateLoader";

export default {
  name: "Page",
  components: { TemplateLoader },
  beforeRouteUpdate(to, from, next) {
    store.dispatch(FETCH_PAGE, {
      page_name: to.params.page_id,
      lang: this.$i18n.locale,
    });
    next()
  },
  data() {
    return {
      userImageUrl: USER_IMAGE_URL,
      live: false,
      past: false,
      future: false,
      startIsToday: false,
      endIsToday: false,
      lang: false,
    };
  },
  watch: {
    page(page) {},
    "$i18n.locale": function (newVal, oldVal) {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
    if (!this.isAuthenticated) {
      this.$store.dispatch(GET_CONFIG);
    }
  },
  methods: {
    ifempty(test, fallback) {
      if (test === "" || test == null) return fallback;
      return test;
    },
    fetch() {
      if (this.isAuthenticated) {
        store.dispatch(FETCH_PAGE, {
          page_name: this.$route.params.page_id,
          lang: this.$i18n.locale,
        });
      } else {
        store.dispatch(FETCH_PAGE_PUBLIC, {
          page_name: this.$route.params.page_id,
          lang: this.$i18n.locale,
        });
      }
    },
    ifemptyhtml(test, fallback) {
      if (test === "" || test === "<p></p>" || test == null) return fallback;
      return test;
    },
  },
  updated() {},
  computed: {
    ...mapGetters([
      "page",
      "isPageLoading",
      "currentUser",
      "isAuthenticated",
      "eventConfig",
    ]),
  },
};
</script>
