<template>
  <component
    :is="component"
    v-bind:data="data"
    v-if="component"
    v-bind:loading="loading"
  />
</template><script>
export default {
  name: "template-loader",
  props: ["data", "type", "loading"],
  data() {
    return {
      component: null,
    };
  },
  watch: {
    /*loading(page) {
      this.loader()
        .then(() => {
          this.component = () => this.loader();
        })
        .catch(() => {
          this.component = () => import("@/components/templates/default/index");
        });
    },*/
    type(newType) {
      this.loader()
        .then(() => {
          this.component = () => this.loader();
        })
        .catch(() => {
          this.component = () => import("@/components/templates/default/index");
        });
    },
  },

  computed: {
    loader() {
      if (!this.type) {
        return null;
      }
      return () => import(`@/components/templates/${this.type}/index`);
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch(() => {
        this.component = () => import("@/components/templates/default/index");
      });
  },
};
</script>