var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"auth-content"},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('LoginHeader'),_vm._v(" "),_c('h6',{},[_vm._v(_vm._s(_vm.$t("signup.header")))]),_vm._v(" "),(_vm.signUpSuccess && !_vm.eventConfig.anonymousMode)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("signup.success")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("signup.checkmailbox")))])]):_vm._e(),_vm._v(" "),(_vm.signUpSuccess && _vm.eventConfig.anonymousMode)?_c('div',{staticClass:"text-left"},[_c('h6',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("signup.reloginData")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t("signup.reloginData.help")))]),_vm._v(" "),(!_vm.email)?_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("signup.username")))]),_vm._v(" "),_c('br'),_vm._v("\n            "+_vm._s(_vm.login_name)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.email)?_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("signup.email")))]),_vm._v(" "),_c('br'),_vm._v("\n            "+_vm._s(_vm.email)+"\n          ")]):_vm._e(),_vm._v(" "),_c('p',[_c('small',[_vm._v(_vm._s(_vm.$t("signup.newPassword")))]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.passwordGenerated)?[_vm._v(_vm._s(_vm.password))]:[_vm._v("("+_vm._s(_vm.$t("signup.yourChosenPassword"))+")")]],2),_vm._v(" "),_c('p',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("signup.gotoHome")))])],1)]):_vm._e(),_vm._v(" "),(!_vm.signUpSuccess)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.eventKey, _vm.email, _vm.name, _vm.password, _vm.passwordVerify)}}},[_c('div',{staticClass:"field mb-2 col-12"},[_c('InputText',{staticClass:"w-100",attrs:{"type":"text","placeholder":_vm.$t('signup.eventKey')},model:{value:(_vm.eventKey),callback:function ($$v) {_vm.eventKey=$$v},expression:"eventKey"}})],1),_vm._v(" "),(
            !_vm.eventConfig.anonymousMode ||
            (_vm.eventConfig.anonymousMode &&
              _vm.eventConfig.anonymousModeAskForName)
          )?_c('div',{staticClass:"field mb-2 col-12"},[_c('InputText',{staticClass:"w-100",attrs:{"type":"text","placeholder":_vm.eventConfig.anonymousMode
                ? _vm.$t('signup.nickname')
                : _vm.$t('signup.name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),(_vm.eventConfig.anonymousMode && !_vm.eventConfig.anonymousModeAskForNameForced)?_c('small',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("signup.nickname.optional.help")))]):_vm._e()],1):_vm._e(),_vm._v(" "),(
            !_vm.eventConfig.anonymousMode ||
            (_vm.eventConfig.anonymousMode &&
              _vm.eventConfig.anonymousModeAskForMail)
          )?_c('div',{staticClass:"field mb-2 col-12"},[_c('InputText',{staticClass:"w-100",attrs:{"type":"email","placeholder":_vm.$t('signup.email'),"help":'hallo'},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),(_vm.eventConfig.anonymousMode && !_vm.eventConfig.anonymousModeAskForMailForced)?[(_vm.eventConfig.anonymousModeShowLoginData)?_c('small',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("signup.email.optional.help")))]):_c('small',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("signup.password.optional.hideLoginData.help")))])]:_vm._e()],2):_vm._e(),_vm._v(" "),(
            !_vm.eventConfig.anonymousMode ||
            (_vm.eventConfig.anonymousMode &&
              _vm.eventConfig.anonymousModeAskForPassword)
          )?_c('div',{staticClass:"field mb-2 col-12"},[_c('InputText',{staticClass:"w-100",attrs:{"type":"password","placeholder":_vm.$t('signup.newPassword')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),(_vm.eventConfig.anonymousMode && !_vm.eventConfig.anonymousModeAskForPasswordForced)?[(_vm.eventConfig.anonymousModeShowLoginData)?_c('small',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("signup.password.optional.help")))]):_c('small',{staticClass:"form-text text-muted"},[_vm._v(_vm._s(_vm.$t("signup.password.optional.hideLoginData.help")))])]:_vm._e()],2):_vm._e(),_vm._v(" "),(
            !_vm.eventConfig.anonymousMode ||
            (_vm.eventConfig.anonymousMode &&
              _vm.eventConfig.anonymousModeAskForPassword)
          )?_c('div',{staticClass:"field mb-2 col-12"},[_c('InputText',{staticClass:"w-100",attrs:{"type":"password","placeholder":_vm.$t('signup.newPasswordVerify')},model:{value:(_vm.passwordVerify),callback:function ($$v) {_vm.passwordVerify=$$v},expression:"passwordVerify"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors != null && _vm.errors.length > 0)?_c('Message',{attrs:{"severity":"info","closable":false}},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index},[_vm._v("\n                  "+_vm._s(_vm.$t(error))+"\n                ")])}),0):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('Button',{staticClass:"mb-4 w-100",attrs:{"type":"submit","disabled":_vm.loading,"icon":_vm.spinnerComputed,"label":_vm.$t('signup.submit')}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-left ml-2 mb-2 small"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t("backend.alreadyUser"))+"\n            "),_c('router-link',{staticStyle:{"text-transform":"uppercase","font-weight":"bold"},attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("backend.login")))])],1),_vm._v(" "),_c('LoginFooter')],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }