import ApiService from "@/common/api.service";
import {
    FETCH_USEREDITFORM,
    UPDATE_USERPROFILEDATA, UPDATE_USERPICTURE, DELETE_USERPICTURE, FETCH_USERINFOFORM,
    UPDATE_USERINFO
} from "./actions.type";
import {
    FETCH_USEREDITFORM_START,
    FETCH_USEREDITFORM_END,
    FETCH_USERINFO_END,
    UPDATE_USERPROFILEDATA_START,
    UPDATE_USERPROFILEDATA_END,
    UPDATE_USERPROFILEPICTURE_START,
    UPDATE_USERPROFILEPICTURE_END,
} from "./mutations.type";

const state = {
    schema: [],
    isUserEditFormLoading: true,
    values: [],
    active: true,
};

const getters = {
    userEditValues(state) {
        return state.values;
    },
    userEditSchema(state) {
        return state.schema;
    },
    isUserEditFormLoading(state) {
        return state.isUserEditFormLoading;
    },
    userinfoValues(state) {
        return state.values;
    },
    userinfoSchema(state) {
        return state.schema;
    },
    userinfoActive(state) {
        return state.active;
    }

};

const actions = {
    [FETCH_USEREDITFORM]({ commit }, language) {
        commit(FETCH_USEREDITFORM_START);
        return ApiService.query('user/getUserEditForm/' + language)
            .then(({ data }) => {
                commit(FETCH_USEREDITFORM_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [UPDATE_USERPROFILEDATA]({ commit }, profileData) {
        commit(UPDATE_USERPROFILEDATA_START);
        return ApiService.post('user/updateUserProfileData', profileData)
            .then(({ data }) => {
                commit(UPDATE_USERPROFILEDATA_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [FETCH_USERINFOFORM]({ commit }, language) {
        commit(FETCH_USEREDITFORM_START);
        return ApiService.query('user/getUserinfoForm/' + language)
            .then(({ data }) => {
                commit(FETCH_USERINFO_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [UPDATE_USERINFO]({ commit }, profileData) {
        commit(UPDATE_USERPROFILEDATA_START);
        return ApiService.post('user/updateUserinfo', profileData)
            .then(({ data }) => {
                commit(UPDATE_USERPROFILEDATA_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [UPDATE_USERPICTURE]({ commit }, profileData) {
        commit(UPDATE_USERPROFILEPICTURE_START);
        return ApiService.postFile('user/updateUserProfilePicture', profileData)
            .then(({ data }) => {
                commit(UPDATE_USERPROFILEPICTURE_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    },
    [DELETE_USERPICTURE]({ commit }) {
        commit(UPDATE_USERPROFILEPICTURE_START);
        return ApiService.postFile('user/deleteUserProfilePicture')
            .then(({ data }) => {
                commit(UPDATE_USERPROFILEPICTURE_END, data);
            })
            .catch(error => {
                throw new Error(error);
            });
    }
};


/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [UPDATE_USERPROFILEDATA_START](state) {
        state.isUpdateUserProfileDataLoading = true;
    },
    [UPDATE_USERPROFILEDATA_END](state, { schema, values }) {
        state.isUpdateUserProfileDataLoading = false;
    },
    [FETCH_USEREDITFORM_START](state) {
        state.isUserEditFormLoading = true;
    },
    [FETCH_USEREDITFORM_END](state, { schema, values }) {
        state.schema = schema;
        state.values = values;
        state.isUserEditFormLoading = false;
    },
    [FETCH_USERINFO_END](state, { schema, values, active }) {
        state.schema = schema;
        state.values = values;
        state.active = active;
        state.isUserEditFormLoading = false;
    },
    [UPDATE_USERPROFILEPICTURE_START](state) {
        state.isUserEditFormLoading = true;
    },
    [UPDATE_USERPROFILEPICTURE_END](state, { schema, values }) {
        state.schema = schema;
        state.values = values;
        state.isUserEditFormLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
