export const CHECK_AUTH = "checkAuth";
export const COMMENT_CREATE = "createComment";
export const COMMENT_DESTROY = "destroyComment";
export const FAVORITE_ADD = "addFavorite";
export const FAVORITE_REMOVE = "removeFavorite";
export const FETCH_ARTICLE = "fetchArticle";
export const FETCH_PARTICIPANTS = "fetchParticipants";
export const FETCH_PARTICIPANT = "fetchParticipant";
export const FETCH_PAGE = 'fetchPage';
export const FETCH_PAGE_PUBLIC = 'fetchPagePublic';
export const FETCH_REDIRECT = 'fetchRedirect';
export const FETCH_REDIRECT_PUBLIC = 'fetchRedirectPublic';

export const FETCH_PROFILE = "fetchProfile";
export const FETCH_PROFILE_FOLLOW = "fetchProfileFollow";
export const FETCH_PROFILE_UNFOLLOW = "fetchProfileUnfollow";
export const FETCH_TAGS = "fetchTags";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER_ACCOUNT_EVENTKEY = "registerAccountEventKey";
export const UPDATE_USER = "updateUser";
export const GET_CONFIG = "getConfig";

export const FETCH_RC_TOKEN = "fetchRcToken";
export const RC_LOGOUT = "rcLogout";

export const FETCH_ROOMS = "fetchRooms";
export const FETCH_WEBINARS = "fetchWebinars";
export const FETCH_WEBINAR = "fetchWebinar";
export const FETCH_USEREDITFORM = "fetchUserEditForm";
export const UPDATE_USERPROFILEDATA = "updateUserProfileData";
export const FETCH_USERINFOFORM = "fetchUserinfoForm";
export const UPDATE_USERINFO = "updateUserinfo";
export const SET_PASSWORD_AUTHCODE = "setPasswordAuthcode";
export const CHECK_AUTHCODE = "checkAuthcode";

export const FETCH_CALENDAR = "fetchCalendar";
export const FETCH_CALENDAR_PUBLIC = "fetchCalendarPublic";
export const ADM_FETCH_CALENDAR_ITEM = "admFetchCalendarItem";

export const FETCH_MEDIA = "fetchMedia";
export const FETCH_STAGE = "fetchStage";
export const FETCH_MENU = "fetchMenu";
export const FETCH_MENU_PUBLIC = "fetchMenuPublic";
export const CLEAR_MENU = "clearMenu";

export const UPDATE_USERPICTURE = "updateUserpicture";
export const DELETE_USERPICTURE = "deleteUserpicture";
export const GET_CHECKININFO = "getCheckinInfo";


