import { render, staticRenderFns } from "./Signup.vue?vue&type=template&id=687b7055&scoped=true&"
import script from "./Signup.vue?vue&type=script&lang=js&"
export * from "./Signup.vue?vue&type=script&lang=js&"
import style0 from "./Signup.vue?vue&type=style&index=0&id=687b7055&prod&scoped=true&lang=css&"
import style1 from "./Signup.vue?vue&type=style&index=1&id=687b7055&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687b7055",
  null
  
)

export default component.exports