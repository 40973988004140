import Vue from "vue";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.withCredentials = true;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  getWParam(resource, params) {
    // remove all values that are "null"
    Object.keys(params).forEach((k) => params[k] == null && delete params[k]);

    return Vue.axios.get(resource + "?" + new URLSearchParams(params)).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  postDl(resource, params) {
    return Vue.axios.post(`${resource}`, params, { responseType: 'blob' });
  },

  postFile(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const RCService = {
  get(slug) {
    return ApiService.get("rc", slug);
  }
}

export const ParticipantsService = {
  query(type, params) {
    return ApiService.query("participants" + (type === "all" ? "/list" : ""), {
      params: params
    });
  },
  get(slug) {
    return ApiService.get("participants/get", slug);
  },
};

