<template>
  <!-- Sidebar -->
  <div
    v-if="isAuthenticated"
    class="bg-light border-right"
    id="sidebar-wrapper"
  >
    <div class="sidebar-heading color">Live Stream Sidebar</div>
    <div
      ref="videoSidebarContainer"
      id="videoSidebarContainer"
      style="width: 100%; height: 300px; background: #eee"
    ></div>
  </div>
</template>

<style>
.sidebar-heading.color {
  color: white;
  background: rgb(191, 31, 68);
  background: linear-gradient(
    90deg,
    rgba(191, 31, 68, 1) 0%,
    rgba(159, 26, 116, 1) 84%
  );
}
#sidebar-wrapper {
  width: 100%;
}
</style>



<script>
import { mapGetters } from "vuex";
import {
  SET_VIDEO_BOX,
  SET_VIDEO_FLOAT,
  SHOW_FLOATY,
} from "../store/mutations.type";
import { FETCH_MEDIA } from "../store/actions.type";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
  methods: {
    resizeVideo(e) {
      e;
      let div = this.$refs.videoSidebarContainer;
      let el = div.getBoundingClientRect();

      this.$store.commit(SET_VIDEO_BOX, [
        el.x - window.scrollX,
        el.y - window.scrollY,
        el.width,
        el.height,
      ]);
    },
  },
  watch: {
    beforeRouteUpdate(to, from) {
      alert("change");
      this.resizeVideo;
    },
  },
  mounted() {
    this.$store.commit(SET_VIDEO_FLOAT, false);
    this.$store.commit(SHOW_FLOATY);
    this.resizeVideo(null);
  },

  created() {
    window.addEventListener("resize", this.resizeVideo);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeVideo);
  },
};
</script>