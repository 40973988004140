export const FETCH_END = "setArticles";
export const FETCH_START = "setLoading";

export const FETCH_ROOMS_START = "setRoomsLoadingStart";
export const FETCH_ROOMS_END = "setRoomsLoadingEnd";

export const FETCH_MENU_START = "setMenuLoadingStart";
export const FETCH_MENU_END = "setMeuLoadingEnd";

export const FETCH_WEBINARS_START = "setWebinarsLoadingStart";
export const FETCH_WEBINARS_END = "setWebinarsLoadingEnd";
export const FETCH_WEBINAR_START = "setWebinarLoadingStart";
export const FETCH_WEBINAR_END = "setWebinarLoadingEnd";

export const PURGE_AUTH = "logOut";
export const SET_PARTICIPANT = "setParticipant";
export const SET_AUTH = "setUser";
export const SET_PASSWORD_SUCCESS = "setPasswordSuccess";
export const SET_AUTHCODE_RESULT = "setAuthcodeResult";
export const SET_COMMENTS = "setComments";
export const SET_ERROR = "setError";
export const SET_CONFIG = "setConfig";
export const SET_CONFIGUPDATEDAT = "setConfigUpdatedAt";
export const SET_UNREADCHATROOMS = "setUnreadChatRooms"
export const SET_LIVECHATENABLED = "setLiveChatEnabled";
export const UNSET_ERROR = "unsetError";
export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const SET_PAGE = "setPage";
export const SET_REDIRECT = "setRedirect";
export const SET_REDIRECT_PUBLIC = "setRedirectPublic";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_PARTICIPANT_IN_LIST = "updateParticipantInList";
export const RESET_STATE = "resetModuleState";
export const SET_CHECKININFO = "setCheckinInfo";




/* Current Main Stage Video */
/* ###################################################### */
export const SET_VIDEO = "setVideo";
export const SET_VIDEO_BOX = "setVideoBox";
export const SET_VIDEO_FLOAT = "setVideoFloat";
export const SET_VIDEO_FROM = "setVideoFrom";
export const HIDE_FLOATY = "hideFloaty";
export const SHOW_FLOATY = "showFloaty";


/* ROCKETCHAT */
/* ###################################################### */
export const SET_ROCKETCHAT_URL = "setRocketChatUrl";
export const SET_ROCKETCHAT_PATH = "setRocketChatPath";
export const SET_ROCKETCHAT_BOX = "setRocketChatBox";
export const SET_ROCKETCHAT_FLOAT = "setRocketChatFloat";
export const SET_CHAT_UNREAD_COUNT = "setChatUnreadCount";
export const HIDE_ROCKETCHAT = "hideRocketChat";

export const SET_RC_TOKEN = "setRcToken";
export const SET_LOGGEDIN_FALSE = "setLoggedinFalse";
export const SET_RC_LOGGEDIN = "setRcLoggedin";
/* ###################################################### */

export const FETCH_CALENDAR_START = "fetchCalendarLoadingStart";
export const FETCH_CALENDAR_END = "fetchCalendarLoadingEnd";

export const FETCH_MEDIA_START = "fetchMediaLoadingStart";
export const FETCH_MEDIA_END = "fetchMediaLoadingEnd";

export const FETCH_STAGE_END = "fetchStageLoadingEnd";

export const FETCH_USEREDITFORM_START = "fetchUserEditFormStart";
export const FETCH_USEREDITFORM_END = "fetchUserEditFormEnd";
export const FETCH_USERINFO_END = "fetchUserinfoEnd";

export const UPDATE_USERPROFILEDATA_START = "updateUserProfileDataStart";
export const UPDATE_USERPROFILEDATA_END = "updateUserProfileDataEnd";

export const UPDATE_USERPROFILEPICTURE_START = "updateUserProfilePictureStart";
export const UPDATE_USERPROFILEPICTURE_END = "updateUserProfilePictureEnd";
