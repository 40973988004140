<template>
  <div class="wrapper">
    <div class="auth-content">
      <Card>
        <template #content>
          <LoginHeader />

          <h6 class="">{{ $t("signup.header") }}</h6>

          <div v-if="signUpSuccess && !eventConfig.anonymousMode">
            <p>{{ $t("signup.success") }}</p>
            <p>{{ $t("signup.checkmailbox") }}</p>
          </div>
          <div v-if="signUpSuccess && eventConfig.anonymousMode" class="text-left">
            <h6 class="mb-4">{{ $t("signup.reloginData") }}</h6>
            <p>{{ $t("signup.reloginData.help") }}</p>
            <p v-if="!email">
              <small>{{ $t("signup.username") }}</small> <br />
              {{ login_name }}
            </p>
            <p v-if="email">
              <small>{{ $t("signup.email") }}</small> <br />
              {{ email }}
            </p>
            <p>
              <small>{{ $t("signup.newPassword") }}</small> <br />
              <template v-if="passwordGenerated">{{ password }}</template>
              <template v-else>({{ $t("signup.yourChosenPassword") }})</template>
            </p>
            <p>
              <router-link to="/" class="btn btn-primary">{{
                $t("signup.gotoHome")
              }}</router-link>
            </p>
          </div>

          <form v-if="!signUpSuccess" @submit.prevent="
            onSubmit(eventKey, email, name, password, passwordVerify)
          ">
            <div class="field mb-2 col-12">
              <InputText v-model="eventKey" type="text" class="w-100" :placeholder="$t('signup.eventKey')" />
            </div>
            <div v-if="
              !eventConfig.anonymousMode ||
              (eventConfig.anonymousMode &&
                eventConfig.anonymousModeAskForName)
            " class="field mb-2 col-12">
              <InputText v-model="name" type="text" class="w-100" :placeholder="
                eventConfig.anonymousMode
                  ? $t('signup.nickname')
                  : $t('signup.name')
              " />
              <small v-if="eventConfig.anonymousMode && !eventConfig.anonymousModeAskForNameForced"
                class="form-text text-muted">{{ $t("signup.nickname.optional.help") }}</small>
            </div>
            <div v-if="
              !eventConfig.anonymousMode ||
              (eventConfig.anonymousMode &&
                eventConfig.anonymousModeAskForMail)
            " class="field mb-2 col-12">
              <InputText v-model="email" type="email" class="w-100" :placeholder="$t('signup.email')"
                :help="'hallo'" />
              <template v-if="eventConfig.anonymousMode && !eventConfig.anonymousModeAskForMailForced">
                <small v-if="eventConfig.anonymousModeShowLoginData" class="form-text text-muted">{{
                  $t("signup.email.optional.help") }}</small>
                <small v-else class="form-text text-muted">{{
                  $t("signup.password.optional.hideLoginData.help")
                }}</small>
              </template>
            </div>
            <div v-if="
              !eventConfig.anonymousMode ||
              (eventConfig.anonymousMode &&
                eventConfig.anonymousModeAskForPassword)
            " class="field mb-2 col-12">
              <InputText v-model="password" type="password" class="w-100"
                :placeholder="$t('signup.newPassword')" />
              <template v-if="eventConfig.anonymousMode && !eventConfig.anonymousModeAskForPasswordForced">
                <small v-if="eventConfig.anonymousModeShowLoginData" class="form-text text-muted">{{
                  $t("signup.password.optional.help") }}</small>
                <small v-else class="form-text text-muted">{{
                  $t("signup.password.optional.hideLoginData.help")
                }}</small>
              </template>
            </div>
            <div v-if="
              !eventConfig.anonymousMode ||
              (eventConfig.anonymousMode &&
                eventConfig.anonymousModeAskForPassword)
            " class="field mb-2 col-12">
              <InputText v-model="passwordVerify" type="password" class="w-100"
                :placeholder="$t('signup.newPasswordVerify')" />
            </div>
            <div class="col-12">
              <transition name="slide-fade">
                <Message severity="info" :closable="false" v-if="errors != null && errors.length > 0">
                  <div v-for="(error, index) in errors" :key="index">
                    {{ $t(error) }}
                  </div>
                </Message>
              </transition>
            </div>
            <div class="col-12">
              <Button type="submit" class="mb-4 w-100" :disabled="loading" :icon="spinnerComputed"
                :label="$t('signup.submit')" />
            </div>
          </form>

          <div class="text-left ml-2 mb-2 small">
            <p>
              {{ $t("backend.alreadyUser") }}
              <router-link to="/login" style="text-transform: uppercase; font-weight: bold">{{ $t("backend.login")
              }}</router-link>
            </p>
            <LoginFooter />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  REGISTER_ACCOUNT_EVENTKEY,
  GET_CONFIG,
  CHECK_AUTH,
} from "@/store/actions.type";
import { UNSET_ERROR } from "@/store/mutations.type";
import LoginFooter from "@/components/TheLoginFooter";
import LoginHeader from "@/components/TheLoginHeader";

export default {
  name: "RwvLogin",
  components: {
    LoginFooter, LoginHeader
  },
  data() {
    return {
      eventKey: null,
      login_name: null,
      name: null,
      email: null,
      password: null,
      passwordVerify: null,
      signUpSuccess: false,
      passwordGenerated: true,
    };
  },
  watch: {
    email: function (val) {
      this.resetErrors();
    },
    emailVerify: function (val) {
      this.resetErrors();
    },
  },
  methods: {
    onSubmit(eventKey, email, name, password, passwordVerify) {
      this.$store
        .dispatch(REGISTER_ACCOUNT_EVENTKEY, {
          credentials: {
            eventKey,
            name,
            email,
            password,
            passwordVerify,
          },
          lang: this.$i18n.locale,
        })
        .then((data) => {
          this.$notification.success(this.$t("user.auth.signup.success"), {
            timer: 5,
          });
          this.signUpSuccess = true;
          if (this.eventConfig.anonymousMode) {
            if (this.eventConfig.anonymousModeShowLoginData) {
              this.password = data.password;
              this.login_name = data.login_name;
              this.passwordGenerated = data.password_generated;
              this.$store.dispatch(CHECK_AUTH);
            } else {
              this.$router.push("/");
            }
          }
        });
    },
    resetErrors() {
      if (this.errors == null) return;
      if (!(Object.entries(this.errors).length === 0)) {
        this.$store.commit(UNSET_ERROR);
      }
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
    this.$store.dispatch(GET_CONFIG);
  },

  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["eventConfig", "isAuthenticated", "darkMode"]),
  },
};
</script>


<style scoped>
.auth-content {
  position: relative;
  width: 480px;
  padding: 15px;
  z-index: 5;
}

.wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}
</style>

<style lang="css" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>